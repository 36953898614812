import {
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useMemo } from 'react';
import moment from 'moment';

import { ACCESS_DETAILS_DEFAULT_VALUES, LIFECYCLE_STATUS, LOCATION_TYPES, SPACE_TYPE_DEFAULT_VALUES } from '../../types/location.types';
import { locationValidator } from './editLocation.validation';
import { setDirtyForm } from '../layout/layout.actions';
import AddressSection from './editComponents/addressSection.component';
import AmenitiesSection from './editComponents/amenitiesSection.component';
import AppSettingsSection from './editComponents/appSettingsSection.component';
import CompanySection from './editComponents/companySection.component';
import HardwareSection from './editComponents/hardwareSection.component';
import InstalledSection from './editComponents/installedSection.component';
import LifecycleStatusSection from './editComponents/lifecycleStatusSection.component';
import MapSection from './editComponents/mapSection.component';
import MultipleCodesInput from './editComponents/multipleCodesInput.component';

function EditLocation({ classes, onChange, selectedLocation, onSelectedLocationUpdate, isAdminMode, isHubspotPreviewMode }) {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDirtyForm(false));
  }, []);
  const validator = useMemo(() => locationValidator(selectedLocation), [selectedLocation]);

  const handleCheckboxChange = event => {
    const { value } = event.target;

    // Toggle the checkbox's checked state
    let updatedAccessDetails;
    if (selectedLocation && selectedLocation.accessDetails) {
      updatedAccessDetails = selectedLocation.accessDetails.includes(value)
        ? selectedLocation.accessDetails.filter(detail => detail !== value)
        : [...selectedLocation.accessDetails, value];
    } else {
      updatedAccessDetails = [value];
    }

    onChange({
      target: {
        value: updatedAccessDetails,
        name: 'accessDetails',
      },
    });
  };
  return (
    <Card className={classes.detailsCard} id="detailsCard">
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              {(selectedLocation.lifecycleStatus !== LIFECYCLE_STATUS.PREBUILT ||
                (selectedLocation.lifecycleStatus === LIFECYCLE_STATUS.PREBUILT && selectedLocation.name)) && (
                <Grid item xs={12}>
                  <TextField
                    label="Location Name"
                    variant="outlined"
                    name="name"
                    fullWidth
                    value={selectedLocation.name}
                    onChange={onChange}
                    helperText={validator.isFieldValid('name') ? '' : 'Location Name is required'}
                    error={!validator.isFieldValid('name')}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <LifecycleStatusSection disabled={isHubspotPreviewMode} onChange={onChange} selectedLocation={selectedLocation} />
              </Grid>
              {selectedLocation.type === LOCATION_TYPES.MAMAVA && selectedLocation.lifecycleStatus !== LIFECYCLE_STATUS.PREBUILT && (
                <Grid item xs={6}>
                  <InstalledSection onChange={onChange} selectedLocation={selectedLocation} />
                </Grid>
              )}
              {selectedLocation.deprecatedDate && (
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Deprecated Date: {moment(selectedLocation.deprecatedDate).format('MM/DD/YYYY')}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Divider
              className={classes.divider}
              sx={{
                opacity: "0.6"
              }} />

            <AppSettingsSection disabled={isHubspotPreviewMode} onChange={onChange} selectedLocation={selectedLocation} />

            <Divider
              className={classes.divider}
              sx={{
                opacity: "0.6"
              }} />

            <AddressSection selectedLocation={selectedLocation} onChange={onChange} />

            <Divider
              className={classes.divider}
              sx={{
                opacity: "0.6"
              }} />

            <MapSection selectedLocation={selectedLocation} onChange={onChange} disabled={isHubspotPreviewMode} />

            <Divider
              className={classes.divider}
              sx={{
                opacity: "0.6"
              }} />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Descriptions</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="description"
                  variant="outlined"
                  label="Description"
                  multiline
                  rows="4"
                  fullWidth
                  value={selectedLocation.description || ''}
                  onChange={onChange}
                  helperText={validator.isFieldValid('description') ? '' : 'Description is required'}
                  error={!validator.isFieldValid('description')}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="details"
                  label="Internal Mamava notes"
                  multiline
                  rows="2"
                  fullWidth
                  value={selectedLocation.details || ''}
                  onChange={onChange}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Divider
              className={classes.divider}
              sx={{
                opacity: "0.6"
              }} />

            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel
                  label="Test Location"
                  control={
                    <Switch
                      aria-label="is test location"
                      name="isTestLocation"
                      checked={selectedLocation.isTestLocation}
                      onChange={e => onChange({ target: { value: e.target.checked, name: 'isTestLocation' } })}
                      disabled={isHubspotPreviewMode}
                      value="isTestLocation"
                      color="primary"
                    />
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AmenitiesSection onChange={onChange} selectedLocation={selectedLocation} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            {selectedLocation.isUserSubmitted && selectedLocation.userWhoSubmitted && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">User Submitted Location</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <a href={`mailto:${selectedLocation.userWhoSubmitted.email}`}>
                      <Typography variant="subtitle">
                        {`${selectedLocation.userWhoSubmitted.firstName} ${selectedLocation.userWhoSubmitted.lastName} (${selectedLocation.userWhoSubmitted.email})`}
                      </Typography>
                    </a>
                  </Grid>
                </Grid>
                <Divider
                  className={classes.divider}
                  sx={{
                    opacity: "0.6"
                  }} />
              </>
            )}

            {selectedLocation.type === LOCATION_TYPES.MAMAVA && (
              <Fragment>
                <CompanySection isAdminMode={isAdminMode} isHubspotPreviewMode={isHubspotPreviewMode} selectedLocation={selectedLocation} onChange={onChange} />
                <Divider
                  className={classes.divider}
                  sx={{
                    opacity: "0.6"
                  }} />
                <HardwareSection isAdminMode={isAdminMode} isHubspotPreviewMode={isHubspotPreviewMode} onSelectedLocationUpdate={onSelectedLocationUpdate} onChange={onChange} selectedLocation={selectedLocation} />

              </Fragment>
            )}

            {selectedLocation.type === LOCATION_TYPES.MAMAVA && (
              <> 
                <Divider
                  className={classes.divider}
                  sx={{
                    opacity: "0.6"
                  }} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Scheduler</Typography>
                  </Grid>
                  <MultipleCodesInput
                    chipHeaderLabel="Password(s)"
                    isDisabled={!selectedLocation.hubspotCompanyId}
                    disabledErrorMessage="No Company set."
                    isHubspotPreviewMode={isHubspotPreviewMode}
                    onCodeChange={accessCodes => onChange(({ target: { name: 'accessCodes', value: accessCodes.map(code=>code.toUpperCase()) } }))}                    
                    codes={selectedLocation.accessCodes || []}
                    inputLabel="Add Scheduler Password"
                    errorMessage="Unable to add access code. Please remove an existing access code and try again."
                    codeInsertionValidation={() => true} 
                  />
                </Grid>
              </>
            )}

            {selectedLocation.type === LOCATION_TYPES.LOCATION && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel shrink>Space Description</InputLabel>
                    <Select
                      aria-label="spaceType"
                      name="spaceType"
                      value={selectedLocation.spaceType}
                      onChange={onChange}
                      input={<OutlinedInput labelWidth={145} />}
                    >
                      {SPACE_TYPE_DEFAULT_VALUES.map((type, index) => (
                        <MenuItem key={index} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {selectedLocation.type === LOCATION_TYPES.LOCATION && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Access Details</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    {ACCESS_DETAILS_DEFAULT_VALUES.map((detail, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={selectedLocation.accessDetails?.includes(detail)}
                            value={detail}
                            onClick={handleCheckboxChange}
                          />
                        }
                        label={detail}
                      />
                    ))}
                  </FormGroup>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

EditLocation.propTypes = {
  selectedLocation: PropTypes.object.isRequired,
  isHubspotPreviewMode: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelectedLocationUpdate: PropTypes.func,
  isAdminMode: PropTypes.bool,
};

EditLocation.defaultProps = {
  disabled: false,
};

const styles = theme => ({
  detailsCard: {
    margin: 10,
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
});
export default withStyles(styles, { withTheme: true })(EditLocation);
