import { Route } from 'react-router-dom';
import { useCheckPermission } from '../../hooks/useCheckPermission';
import PropTypes from 'prop-types';
import React from 'react';

export const PrivateRoute = ({path, component, requiredPermission, ...routeProps}) => {
  const {isAuthorized} = useCheckPermission(requiredPermission);

  return (
    isAuthorized(requiredPermission) ? <Route path={path} component={component} {...routeProps}/> : <div style={{padding: 10}}>Unauthorized</div>
  );
};

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.object.isRequired,
  requiredPermission: PropTypes.string.isRequired,
};
