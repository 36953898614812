import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, OutlinedInput, Select, Switch, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { APP_DISPLAY_LEVELS, LOCATION_TYPES } from '../../../types/location.types';

function AppSettingsSection({ disabled, onChange, selectedLocation }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">App Settings</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>       
          <FormControl
            variant="outlined"
            fullWidth
          >
            <InputLabel shrink>App Display Setting</InputLabel>
            <Select
              aria-label="appDisplayLevel"
              name="appDisplayLevel"
              value={selectedLocation.appDisplayLevel}
              onChange={onChange}
              disabled={disabled}
              input={
                <OutlinedInput labelWidth={170} />
              }
            >
              <MenuItem value={APP_DISPLAY_LEVELS.DISPLAY_ALWAYS}>
                Always Display
              </MenuItem>
              <MenuItem value={APP_DISPLAY_LEVELS.DISPLAY_BLUETOOTH_ONLY} disabled={selectedLocation.type !== LOCATION_TYPES.MAMAVA}>
                Display Bluetooth Only
              </MenuItem>
              <MenuItem value={APP_DISPLAY_LEVELS.DISPLAY_NEVER}>
                Never Display
              </MenuItem>
            </Select>
          </FormControl>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                label="Label as &quot;Limited Access&quot;"
                control={
                  <Switch
                    aria-label="limited access"
                    name="isLimitedAccess"
                    checked={selectedLocation.isLimitedAccess}
                    onChange={e => {onChange({ target: { value: e.target.checked, name: 'isLimitedAccess' } });}}
                    disabled={disabled}
                    value="isLimitedAccess"
                    color="primary"
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                label="Label as &quot;Temporarily Down&quot;"
                control={
                  <Switch
                    aria-label="temporarily down"
                    name="isTemporarilyDown"
                    checked={selectedLocation.isTemporarilyDown}
                    onChange={e => {onChange({ target: { value: e.target.checked, name: 'isTemporarilyDown' } });}}
                    disabled={disabled}
                    value="isTemporarilyDown"
                    color="primary"
                  />
                }
              />     
            </Grid>
          </Grid>    
        </FormControl>
      </Grid>
    </Grid>
  );
}

AppSettingsSection.propTypes = {
  disabled: PropTypes.bool,
  selectedLocation: PropTypes.object.isRequired,

  onChange: PropTypes.func.isRequired,
};

export default AppSettingsSection;