import {
  CLEAR_USER_ACCESS_CODE_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  TOGGLE_USER_FLAG_REQUEST,
  TOGGLE_USER_FLAG_SUCCESS,
} from './users.actions';
import { findIndex } from 'lodash';
import Immutable from 'immutable';

const initialState = Immutable.Map({
  users: [],
  isLoading: false,
  isToggling: false,
  errors: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return state.set('users', action.response).set('isLoading', false);

    case GET_USERS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_USERS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case TOGGLE_USER_FLAG_SUCCESS: {
      let users = [...state.get('users')];
      let userIdx = findIndex(users, { id: action.actionMetadata.userId });
      users[userIdx][action.actionMetadata.flagName] = !users[userIdx][action.actionMetadata.flagName];

      return state.set('users', users).set('isToggling', false);
    }

    case TOGGLE_USER_FLAG_REQUEST:
      return state.set('isToggling', true);
    case CLEAR_USER_ACCESS_CODE_SUCCESS: {
      const users = [...state.get('users')];
      const userIdx = findIndex(users, { id: action.actionMetadata.userId });
      users[userIdx].accessCodes = [];

      return state.set('users', users);
    }

    default:
      return state;
  }
}
