import { Button, Input, Tab, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Tabs } from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { findIndex, isEmpty, slice } from 'lodash';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import { CLEAR_USER_ACCESS_CODE_SUCCESS, TOGGLE_USER_FLAG_SUCCESS, clearUserAccessCodes, getUsers, toggleUserFlag } from './users.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';
import Colors from '../../styles/colors';

const DATA_COLUMNS = [
  { fieldName: 'firstName', displayName: 'First Name' },
  { fieldName: 'lastName', displayName: 'Last Name' },
  { fieldName: 'email', displayName: 'Email Address' },
  { fieldName: 'firebaseId', displayName: 'Firebase ID' },
];

class UsersContainer extends Component {
  constructor(props) {
    super(props);

    ['handleToggleFlag'].map((key) => (this[key] = this[key].bind(this)));
  }

  state = {
    order: 'asc',
    orderBy: 'lastName',
    currentUsers: [],
    filteredUsers: [],
    page: 0,
    rowsPerPage: 10,
    filters: {
      firstName: '',
      lastName: '',
      email: '',
    },
    tabIndex: 0,
  };

  async UNSAFE_componentWillMount() {
    this.props.setPageTitle('Users');
    await this.props.getUsers();

    let rowsPerPage = localStorage.getItem('rowsPerPage');
    if (parseInt(rowsPerPage, 10) >= 0) {
      this.setState({ rowsPerPage: parseInt(rowsPerPage, 10) });
    }
    this.buildCurrentUsers();
  }

  buildCurrentUsers() {
    let { page, rowsPerPage } = this.state;
    let users = this.filterArray(this.props.users, this.state.filters);
    this.setState({
      currentUsers: slice(users, page * rowsPerPage, (page + 1) * rowsPerPage),
      filteredUsers: users,
    });
  }

  initializeList(users) {
    // NO-OP RIGHT NOW - this is used for
    // return users.map((user) => {
    //   return user;
    // });
  }

  filterArray(array, filters) {
    let currentArray = array;
    if (array) {
      currentArray = this.initializeList(currentArray);
      currentArray = array
        .map((record) => {
          let returnRecord = record;
          if (filters && Object.keys(filters).length > 0) {
            Object.keys(filters).map((filter) => {
              if (returnRecord) {
                if (
                  !isEmpty(filters[filter]) &&
                  (!record || !record[filter] || !record[filter].toLowerCase().includes(filters[filter].toLowerCase()))
                ) {
                  returnRecord = undefined;
                }
              }
              return returnRecord;
            });
          }
          return returnRecord;
        })
        .filter((record) => (record ? true : false));
    }

    return currentArray;
  }

  handleUpdateFilters(filter, value) {
    let filters = this.state.filters;
    filters[filter] = value;
    this.setState({ state: { filters } }, this.buildCurrentUsers);
  }

  handleFilterChange = (field, value) => {
    this.handleUpdateFilters(field, value);
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, this.buildCurrentUsers);
  };

  handleChangeRowsPerPage = (event) => {
    localStorage.setItem('rowsPerPage', event.target.value);
    this.setState({ rowsPerPage: event.target.value }, this.buildCurrentUsers);
  };

  async handleToggleFlag(userId, flag) {
    let userIndex = findIndex(this.props.users, { id: userId });
    let { users } = this.props;
    let user = users[userIndex];
    let hasAccess = user[flag];

    let question = `Are you sure you wish to ${hasAccess ? 'revoke' : 'grant'} ${flag === 'isAdmin' ? 'admin' : 'debug'} access ${
      hasAccess ? 'from' : 'to'
    } ${user.firstName} ${user.lastName}?`;
    let statement = ' Doing this will require that user to logout and then login again.';

    if (!this.props.isToggling && window.confirm(question + statement)) {
      let response = await this.props.toggleUserFlag(userId, flag);
      if (response.type === TOGGLE_USER_FLAG_SUCCESS) {
        this.props.handleToastMessage('User updated.');
      } else {
        this.props.handleToastMessage('User update failed.');
      }
    }
  }

  async handleClearAccessCodes(userId) {
    const response = await this.props.clearUserAccessCodes(userId);
    if (response.type === CLEAR_USER_ACCESS_CODE_SUCCESS) {
      this.props.handleToastMessage('Access codes successfuly cleared.');
    } else {
      this.props.handleToastMessage('Access codes clearing failed.');
    }
  }

  render() {
    let { classes } = this.props;
    let { tabIndex } = this.state;

    return (
      <Fragment>
        <Tabs className={classes.tabs} value={this.state.tabIndex} onChange={(e, value) => this.setState({ tabIndex: value })}>
          <Tab label="Users List" className={classes.tab} />
        </Tabs>
        {tabIndex === 0 && (
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column', height: '75vh', paddingTop: 10, overflowY: 'auto' }}>
            {this.props.isLoading && <div className="loader" />}
            {!this.props.isLoading && (
              <div className={classes.tableWrapper} id="tableWrapper">
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {DATA_COLUMNS.map((column, i) => {
                        return (
                          <TableCell className={classes.head} key={i}>
                            <div>{column.displayName}</div>
                            <div>
                              <Input
                                margin="none"
                                id={`filter_${column.fieldName}`}
                                variant="caption"
                                placeholder="filter"
                                className={classes.textField}
                                classes={{
                                  root: classes.root,
                                }}
                                fullWidth
                                value={this.state.filters[column.fieldName]}
                                onChange={(e) => this.handleFilterChange(column.fieldName, e.target.value)}
                                type={column.type ? column.type : 'text'}
                              />
                            </div>
                          </TableCell>
                        );
                      })}

                      <TableCell className={classes.head}>
                        <div>Actions</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.currentUsers.map((user, i) => {
                      return (
                        <TableRow key={user.id}>
                          {DATA_COLUMNS.map((column, i) => {
                            return (
                              <TableCell className={classes.cell} component="td" scope="row"
                                key={i}
                              >
                                {user[column.fieldName]}
                              </TableCell>
                            );
                          })}

                          <TableCell className={classes.cell}>
                            <Button
                              variant="contained"
                              color={user.isAdmin ? 'primary' : 'secondary'}
                              className={classes.actionButton}
                              onClick={() => this.handleToggleFlag(user.id, 'isAdmin')}
                            >
                              Admin Access
                            </Button>
                            <Button
                              variant="contained"
                              color={user.canDebug ? 'primary' : 'secondary'}
                              className={classes.actionButton}
                              onClick={() => this.handleToggleFlag(user.id, 'canDebug')}
                            >
                              Debug BLE
                            </Button>
                            {!!user.accessCodes.length && 
                            <Button
                              variant="contained"
                              color={'primary'}
                              className={classes.actionButton}
                              onClick={() => this.handleClearAccessCodes(user.id)} 
                            >
                              Clear Access Codes 
                            </Button> }
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            )}

            {!this.props.isLoading && (
              <div style={{ flex: 1, paddingRight: 100 }}>
                <TablePagination
                  component="div"
                  count={this.state.filteredUsers.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  style={{ flex: 0 }}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </div>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

const styles = (theme) => ({
  root: {
    flex: 1,
    marginTop: theme.spacing(3),
    margin: 20,
  },
  actionButton: {
    marginRight: '1rem',
  },
  tableWrapper: {
    padding: '1rem',
    overflowX: 'auto',
  },
  tableBody: {
    overflowY: 'auto',
    height: '75vh',
  },
  button: {
    margin: theme.spacing(1),
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  head: {
    position: 'sticky',
    top: 0,
    backgroundColor: Colors.white,
  },
  textField: {
    boxShadow: 'none',
    fontWeight: 'normal',
    border: 0,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 0,
    marginRight: 0,
    fontSize: 12,
  },
  cell: {
    color: Colors.primary.main,
  },
  input: {
    height: 20,
  },
  link: {
    color: Colors.primary.main,
    textDecoration: 'none',
  },
  tabs: {
    backgroundColor: Colors.secondary.main,
  },
  tab: {
    color: Colors.white,
    fontWeight: 'bold',
  },
});

UsersContainer.propTypes = {
  users: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isToggling: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  toggleUserFlag: PropTypes.func.isRequired,
  clearUserAccessCodes: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,

  classes: PropTypes.object.isRequired,

  // Injected by React Router
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    users: state.users.get('users'),
    isLoading: state.users.get('isLoading'),
    isToggling: state.users.get('isToggling'),
    errors: state.locations.get('errors'),
  };
};

const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(
    mapStateToProps,
    {
      setPageTitle,
      getUsers,
      handleToastMessage,
      toggleUserFlag,
      clearUserAccessCodes,
    }
  ),
);

export default prepareForExport(UsersContainer);